import React from "react";
import moment from "moment";

import Link from "@/utils/link";

import FooterLogo from "../Logo/FooterLogo";
import { host } from "@/utils/config";

import { FaTwitter, FaLinkedinIn, FaFacebookF, FaInstagram } from "react-icons/fa";

const HomeFooter = ({ small = false }) => {
    return (
        <div style={{ backgroundColor: "#302d33" }}>
            <div className="container mx-auto px-8 sm:px-16 py-16">
                <div
                    className={
                        "grid grid-col-2 gap-x-8 gap-y-8" + (small ? "" : " sm:grid-cols-6")
                    }
                >
                    <div className="col-span-2">
                        <div className="mt-2 mr-6" style={{ maxWidth: 256 }}>
                            <FooterLogo />
                        </div>

                        <div className="mt-8 flex space-x-4">
                            <Link href="https://instagram.com/purpledoorfinders">
                                <a className="p-2 bg-gray-500 rounded-full hover:bg-gray-600">
                                    <FaInstagram className="w-6 h-6 text-white" />
                                </a>
                            </Link>
                            <Link href="https://facebook.com/purpledoorfinders">
                                <a className="p-2 bg-gray-500 rounded-full hover:bg-gray-600">
                                    <FaFacebookF className="w-6 h-6 text-white" />
                                </a>
                            </Link>
                            <Link href="https://twitter.com/findpurpledoor">
                                <a className="p-2 bg-gray-500 rounded-full hover:bg-gray-600">
                                    <FaTwitter className="w-6 h-6 text-white" />
                                </a>
                            </Link>
                            <Link href="https://linkedin.com/company/purple-door-finders">
                                <a className="p-2 bg-gray-500 rounded-full hover:bg-gray-600">
                                    <FaLinkedinIn className="w-6 h-6 text-white" />
                                </a>
                            </Link>
                        </div>

                        <p className="mt-8 text-gray-100">
                            &copy;{moment().format("YYYY")} Purple Door, LLC
                        </p>
                    </div>

                    <div className="text-gray-100">
                        <h2 className="mt-2 mb-6 font-bold">FOR RESIDENTS</h2>

                        <LinkItem href="/how-it-works">How It Works</LinkItem>
                        <LinkItem href="/my-account">My Account</LinkItem>
                        <LinkItem href="/resources">Resources</LinkItem>
                        <LinkItem href="/contact-us">Contact Us</LinkItem>
                        <LinkItem href="/help">Help</LinkItem>
                    </div>

                    <div className="text-gray-100">
                        <h2 className="mt-2 mb-6 font-bold">COMPANY</h2>

                        <LinkItem href="/about-us">About Us</LinkItem>
                        <LinkItem href="https://www.linkedin.com/company/purple-door-finders">
                            Careers
                        </LinkItem>
                        <LinkItem href="/resources/articles">Articles</LinkItem>
                        <LinkItem href="/newsroom">Newsroom</LinkItem>
                        <LinkItem href="/endorsement">Endorsements</LinkItem>
                    </div>

                    <div className="text-gray-100">
                        <h2 className="mt-2 mb-6 font-bold">PRIVACY</h2>

                        <LinkItem href="/pages/privacy-policy">Privacy Policy</LinkItem>
                        <LinkItem href="/pages/terms-of-use">Terms of Use</LinkItem>
                        {/*<LinkItem href="/pages/legal-notices">Legal Notices</LinkItem>*/}
                        <LinkItem href="/pages/do-not-sell-my-info">
                            Do Not Sell My Info
                        </LinkItem>
                    </div>

                    <div className="text-gray-100">
                        <h2 className="mt-2 mb-6 font-bold">FOR COMMUNITIES</h2>

                        <div className="hidden sm:block">
                            <LinkItem
                                href={host + "community-portal/community-how-it-works"}
                            >
                                How It Works
                            </LinkItem>
                        </div>

                        <LinkItem href={host + "community-portal"}>
                            List My Community
                        </LinkItem>

                        <LinkItem href={host + "community-portal"}>
                            Manage My Community
                        </LinkItem>

                        <div className="hidden sm:block">
                            <LinkItem href={host + "community-portal/support"}>
                                Community Support
                            </LinkItem>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const LinkItem = ({ href, children }) => {
    return (
        <Link href={href}>
            <a className="block py-1 hover:text-primary-200">{children}</a>
        </Link>
    );
};

export default HomeFooter;
