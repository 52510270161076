import React, { useState } from "react";

import Link from "@/utils/link";

import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline";

const cities = [
    { name: "Albuquerque", url: "Albuquerque-NM" },
    { name: "Atlanta", url: "Atlanta-GA" },
    { name: "Baltimore", url: "Baltimore-MD" },
    { name: "Birmingham", url: "Birmingham-AL" },
    { name: "Charlotte", url: "Charlotte-NC" },
    { name: "Chicago", url: "Chicago-IL" },
    { name: "Cincinnati", url: "Cincinnati-OH" },
    { name: "Cleveland", url: "Cleveland-OH" },
    { name: "Colorado Springs", url: "Colorado-Springs-CO" },
    { name: "Corvalis ", url: "Corvalis-OR" },
    { name: "Denver", url: "Denver-CO" },
    { name: "Fairfax", url: "Fairfax-VA" },
    { name: "Fairfield", url: "Fairfield-CT" },
    { name: "Houston", url: "Houston-TX" },
    { name: "Indianapolis", url: "Indianapolis-IN" },
    { name: "Jacksonville", url: "Jacksonville-FL" },
    { name: "Knoxville", url: "Knoxville-TN" },
    { name: "Las Vegas", url: "Las-Vegas-NV" },
    { name: "Los Angeles", url: "Los-Angeles-CA" },
    { name: "Louisville", url: "Louisville-KY" },
    { name: "Mesa", url: "Mesa-AZ" },
    { name: "Miami", url: "Miami-FL" },
    { name: "Minneapolis", url: "Minneapolis-MN" },
    { name: "New York", url: "New-York-NY" },
    { name: "Oklahoma City", url: "Oklahoma-City-OK" },
    { name: "Olympia", url: "Olympia-WA" },
    { name: "Overland Park", url: "Overland-Park-KS" },
    { name: "Philadelphia", url: "Philadelphia-PA" },
    { name: "Phoenix", url: "Phoenix-AZ" },
    { name: "Pittsburgh", url: "Pittsburgh-PA" },
    { name: "Portland", url: "Portland-OR" },
    { name: "Reno", url: "Reno-NV" },
    { name: "Richmond", url: "Richmond-VA" },
    { name: "Sacramento", url: "Sacramento-CA" },
    { name: "Saint Louis", url: "Saint-Louis-MO" },
    { name: "San Antonio", url: "San-Antonio-TX" },
    { name: "Sarasota", url: "Sarasota-FL" },
    { name: "Seattle", url: "Seattle-WA" },
    { name: "Spokane", url: "Spokane-WA" },
    { name: "Tacoma", url: "Tacoma-WA" },
];

const SeoLinks = ({ small = false, enable = false }) => {
    if (!enable) {
        return null;
    }

    return (
        <div className="bg-gray-100">
            <div className="container mx-auto px-8 sm:px-16">
                <div className="py-16">
                    <div
                        className={
                            "grid grid-cols-1 gap-x-16 gap-y-8" +
                            (small ? "" : " sm:grid-cols-3")
                        }
                    >
                        <SeoGroup list={cities} title="TOP MARKETS" />
                        <SeoGroup list={cities} title="POPULAR SEARCHES" />
                        <SeoGroup list={cities} title="RESOURCES" />
                    </div>
                </div>
            </div>
        </div>
    );
};

const SeoGroup = ({ list, title }) => {
    const [showMore, setShowMore] = useState(true);

    return (
        <div>
            <h2 className="mb-2 text-xl text-gray-700">{title}</h2>
            <div className="grid grid-cols-2 gap-1">
                {list.map((item, index) => (
                    <Link key={item.url} href={"/" + item.url}>
                        <a
                            className={
                                "text-md text-blue-700 hover:underline" +
                                (showMore && index >= 10 ? " hidden" : "")
                            }
                        >
                            {item.name}
                        </a>
                    </Link>
                ))}
            </div>

            <button
                onClick={() => setShowMore(!showMore)}
                className="mt-4 -ml-2 px-2 py-1 flex items-center text-primary-400 rounded hover:bg-gray-100"
            >
                {showMore ? "Show More" : "Show Less"}
                {showMore ? (
                    <ChevronDownIcon className="ml-2 w-4 h-4" />
                ) : (
                    <ChevronUpIcon className="ml-2 w-4 h-4" />
                )}
            </button>
        </div>
    );
};

export default SeoLinks;
